@media only screen and (max-width:992px ) {
	/*body{font-size:14px;}*/
	.socialnetworks{display: none;}
	.header .container{display: block;width: 100%;}
	.header-top{padding-left: 8px;padding-bottom: 5px;}
	.header .logo{float: left;width: 130px; padding-top: 6px;padding-left: 15px;}
	.header .header-tabs{border-bottom: none;}
	.header-top .nav a{font-size: 13px;}
	.nav-tabs>li.active>a, 
	.nav-tabs>li.active>a:focus, 
	.nav-tabs>li.active>a:hover {background-color: #ddd !important;border: none; border-radius: 4px;}
	.header-bottom{background: #406eb3;padding: 0 15px;position: relative;}
	.header-bottom .online-link{position: absolute;left: 65px;right: 65px;width: auto;height:auto;display: inline-block;top: 3px;}
	.header-bottom input.animation-input{width: 100%;opacity: 1;}
	.header-bottom .nav-utility a img{width: 32px;}
	.header-bottom .dropdown-menu-right{right: -10px;}
	.header-bottom .menu-top li{float: none;}
	.header-bottom .menu-top li a{background: #406eb3;color: #fff !important;}
	.header-bottom .dropmenu-wrap{position: static;height: 0;opacity: 1;overflow: hidden;}
	.header-bottom .dropmenu-wrap>ul {background: #406eb3; visibility: visible; padding-bottom: 0;
	 margin-top: 0 !important;-webkit-box-shadow: none; box-shadow: none;  border: none; transform: translateY(0) !important;}
	  
	.header-bottom .nav-border-bottom>li { border-bottom: 1px solid rgba(255,255,255,.5);}
	.header-bottom .nav-border-bottom>li>a {border-bottom:none !important;}
	.header-bottom .nav-border-bottom>li>a:hover{border-bottom:none !important;}
	.header-bottom .nav-border-bottom>li>a .caret{transform: scale(1.5);margin-left: 10px;}
	
	.header-bottom .dropmenu-wrap{/*margin-top: -10px;*/}
	.header-bottom .dropmenu-wrap>ul>li{padding: 0 0 0 27px;line-height: 33px;}
	.header-bottom .dropmenu-wrap>ul>li:hover{background: transparent;}
	.header-bottom .dropmenu-wrap>ul>li a {padding: 0;}
	
	
	.header-bottom .menu-top{/*width: 100%;float: left;*/margin: 41px -15px 0 -15px;-webkit-box-shadow: none; box-shadow: none; padding: 0;}
	.header-bottom .menu-top ul li {margin-right: 0;padding-left: 15px;}
	.header-bottom .navbar-toggle{float: left;padding: 4px 0;background: url(../../images/bg-close-o.png) no-repeat center center; background-size: 100% 100%;}
	.header-bottom .navbar-toggle.collapsed{background:none;}
	.header-bottom .navbar-toggle.collapsed .icon-bar{background: #fff;}	
	
	.footer-logo dd{width: 37%;float: left;}
	.footer-logo dt{color: #7f7f7f !important;}
	.footer-bottom{font-size: 12px;}
	.footer-bottom .container{padding: 10px 15px;}
	
	
	.search-tips {right:0 !important;top: 40px;z-index: 10000;left:0 !important;width: 100%;padding-left: 15px;padding-right: 15px;}
	
	
	.banner{padding: 20px 0;}
	.banner .container {height: auto;}
	.banner h2 {font-size: 20px;}
	.banner p {font-size: 14px;}
	
	.banner .seedemo{display: inline-block;}
	.banner .btn{font-size: 12px;margin-right: 15px;}
	.title-h1 {font-weight: 300 !important;font-size: 20px !important;}
	/*.upload-panel .panel-heading {font-size: 16px;}
	.upload-panel .panel-heading i{font-size: 18px;}
	.main-text-upload .panel-body .nav-pills a {font-size: 14px;}
	.upload-panel .file-drop-zone-title {padding: 52px 10px;}
	.upload-panel .panel-body {padding: 15px 10px 0px 10px;}
	.upload-panel .panel-heading {padding-left: 10px;}
	.upload-wrap .krajee-default .file-other-icon {font-size: 80px;}
	.upload-wrap .krajee-default .file-other-icon i {font-size: 50px;vertical-align: middle;}
	.upload-wrap .content { height: 180px;}
	.upload-wrap .file-drop-zone {height: 160px;}
	.match-play-vip .left > .border{border: none;padding: 30px 0;}*/
	
	.bg-w-box {width: auto;max-width: 600px;border:none;padding: 0 30px;}
	.po-login-box {width: auto;}
	.login-body{background-color:#fff;}
	.login-wrap .other-login .btn{font-size: 12px;}
	.login-wrap .desc{font-size: 12px;}
	
	/*grade*/
	.grade-section .grid > div{margin-bottom: 60px;}
	.grade-section .grid{margin-bottom: -60px;}
	.grade-read h3{font-size: 16px;}
	
	.grade-coach-list .grid > div{margin-bottom: 20px;}
	.grade-coach-list .grid{margin-bottom: -20px;}
	.grade-coach-list .select-d::after{content: '';display: table;height: 0;clear: both;}
	.grade-coach-list .search-coach-btn .btn {
		width: 100% !important;
	}
	
	.grade-coach-list .media-right {
		display: block;
		padding-left: 0;
		padding-top: 20px;
	}
	
	.grade-coach-list .media:last-child {
		margin-bottom: 0;
	}
	
	.grade-coach-list .media .select-right .btn {
		width: 100%;
	}

	.grade-coach-list .media .media-heading,
	.grade-coach-list .media .media-footer {
		width: 100%;
	}
	
	.grade-coach-list .media .media-heading .desc span {
		display: block;
	}
	
	.grade-detail{padding: 10px 0 20px 0;}
	.grade-detail .left .summary .media-right {
		display: block;
		padding-top: 15px;
		padding-left: 0;
	}
	
	.grade-detail .left .summary .media-right .cont {
		width: 100%;
	}
	
	.grade-detail .left .summary .media-right span {
		float: left;
	}
	
	.grade-detail .left .summary .media-right img.best1 {
		margin-left: 0;
	}
	
	.grade-detail .left .summary .serivice a {
		font-size: 12px;
	}
	
	.grade-detail .left .summary .serivice a::after {
		content: ",";
	}
	
	.grade-detail .left .summary .serivice a:last-child::after {
		content: "";
	}
	
	.grade-detail .left .ratings .score-wrap, 
	.grade-detail .left .ratings .score-wrap .score,
	.grade-detail .left .ratings .score-wrap .score .star,
	.grade-detail .left .ratings .connect-wrap{
		font-size: 12px;
	}
	.grade-detail .left .ratings .score-wrap .score .stars{
		display: inline-block;
	}
	.grade-detail .left .ratings .score-wrap .score .star{
		margin: 0 1px;
	}
	.grade-detail .left .ratings .connect i {
	    font-size: 16px;
	    margin: 0 2px;
	    vertical-align: text-bottom;
	}
	.grade-detail .left .ratings {
	    margin-top: 8px;
	    margin-bottom: 5px;
	   
	}
	.grade-detail .left .ratings .score-wrap .score{
		margin-bottom: 5px;
	}
	.grade-detail .left .ratings .score-wrap, .grade-detail .left .ratings .connect-wrap {
	    padding: 5px 10px;
	}
	.grade-detail .left .ratings .connect {
	    height: 41px;
	    line-height: 41px;
	}
	.grade-detail .right .panel-buy {
	   position: static;
	}
	.grade-detail .right .panel-buy .panel-body [data-toggle="tooltip"]{
		margin-top: -10px;
		margin-right: -5px;
	}
	.grade-detail .nav-justified > li {
	    display: table-cell;
	    width: 1%;
	}
	.grade-detail  .nav-justified > li > a {
	    margin-bottom: 0;
	}	
	
	.grade-detail .left .review-section .media-body,
	.grade-detail .left .review-section .media-left{
		display: block;
		width: 100%;
	}
	.grade-detail .left .review-section .media-left> div {
	     width: 100%; 
	     font-size: 12px;
	}
	.grade-detail .left .review-section .media-left .stars,
	.grade-detail .left .review-section .media-left p{
		display: inline-block;
	}
	.grade-detail .left .review-section .media-left .star{
		font-size: 12px;
	}
	.grade-detail .left .review-section .review-section-more {
	    width: 100%;
	}
	.grade-detail .messag-wrap {
	    position: fixed;
	    right: 15px;
	    bottom: 63px;
	    width: 340px;
	    z-index: 1000;
	}
	.grade-detail .messag-wrap .message-btn {
	     width: auto; 
	     height: auto; 
	     line-height: 1; 
	     border-radius: 50%; 
	     box-shadow: none; 
	    position: absolute;
	    right: 5px;
	    bottom: -25px;
	    text-align: center;
	    font-size: 40px;
	}
	.grade-detail .messag-wrap .messag-content{
		display: none;
	}
	.grade-detail .left .scrollspy-wrap{height: auto !important;}
	
	
	.grade-coach-list .media-body{display: block;width: 100%;}
	.grade-coach-list .media-heading{padding-left: 105px;min-height: 130px;}
	.grade-coach-list .media-left{float: left;display: block;margin-bottom: -130px;}
	
	.grade-pay .order-summary{
		display: none;;
	}	
	
	
	
	/*.match-play-vip .right{display:none;}*/
	/*.match-play-vip .left .border .title, .match-play-vip .left .border> h5 {
	    padding-left: 35px;
	}
	.match-play-vip .left .border .title .ball, .match-play-vip .left .border> h5>span {
	    margin-left: -35px;
	}*/
	
	.grade-section .cont-left, .grade-section .cont-center, .grade-section .cont-right, .grade-member .cont {
	     padding: 0; 
	}
	.resumelist-section .grid{
		margin-bottom: -30px;
	}
	.resumelist-section .grid > div{
		margin-bottom: 30px;
	}
	.resumelist-section .cont-left .icon-wrap img, .resumelist-section .cont-center .icon-wrap img, .resumelist-section .cont-right .icon-wrap img {
	    height: 40px;
	}
	.resumelist-section .cont-left .icon-wrap, .resumelist-section .cont-center .icon-wrap, .resumelist-section .cont-right .icon-wrap {
	    margin-bottom: 20px;
	}
	.advantage {
	     height: auto; 
	}
	.advantage .container {
	    height: auto; 
	    background: none; 
	}
	.advantage .siyoudian h1{
		font-weight: 500;
		font-size: 35px;
		margin: 20px 0 15px;
	
	}
	.advantage .adv-left1{
		display: none;
	}
	.bulid-resumes h4 {
	    font-size: 14px;
	    font-weight: 300; 
	}
	.certification{
		display: none;
	}
	.grade-member .cont {
	    padding-top: 15px;
	    margin-bottom: 30px;
	}
	.bulid-resumes .one_third{
		text-align: left;
	}
	/*.resume-template .right{
		display: none;
	}*/
	
	.resume-template-header .page-header{
		margin-bottom: 0;
		font-size: 16px;
	}
	.resume-template .nav-border-bottom{
		background: #eee;
		font-size: 0;
	    height: 40px;
	    line-height: 38px;
	    margin-top: -1px;
	    margin-bottom: 20px;
	}
	.resume-template .nav-border-bottom .container{margin-top: 0;padding-right: 0;padding-left: 0;}
	.resume-template .nav-border-bottom li{
		display: inline-block;
		text-align: center;
	}
	.resume-template .nav-border-bottom li a{
		display: block;
		font-size: 16px;
		min-width: 57px;
		margin: 0 auto;
		margin-left: 10px;
	}
	.resume-template .nav-border-bottom li.active a{
		border-bottom: 2px solid #27aafd ;
	}
	.resume-template .container{
		margin-top: 20px;
	}
	.resume-template .thumbnail-img {
	    margin-left: -15px;
	}
	.resume-template .thumbnail-img-item {
		display: inline-block;
	}
	.resume-template .thumbnail-img li {margin-left: 0;}
	.resume-template .thumbnail-img-item img { width: 100%;}
	.resume-template .tag-temp {top: auto; bottom: 5px;}
	
	/*.panda-show .content {width: 90%; }
	.resume-preview-wrap {width: 100%; }
	.panda-show .swiper-slide {height: auto; padding: 0 20px; }
	.resume-template-detail{padding-top:30px;}
	.page-header {margin-top: 20px;}
	.panda-show .arrow-left {left: -30px;}
	.panda-show .arrow-right {right: -30px;}
	.convert .sub-title{font-size: 16px;}
	.conver .upload-panel .panel-heading {font-size: 20px;}
	.conver .upload-panel .panel-body> p {font-size: 16px;}*/
	
	/*.upload-panel .choose-file{bottom: auto;top: 50%;transform: translate(-50%,-50%);width: 100%;}
	.preview-wrap .file-preview-frame {display: block;margin-top: 0; margin-bottom: 20px;padding: 10px;}
	.preview-wrap .file-actions-cell,
	.preview-wrap .file-actions {display: block;}
	.conver .preview-wrap .file-footer-buttons {text-align: left; }
	.preview-wrap .file-details-cell{width: 100%;display: inline-block;padding-left: 50px;padding-right: 0;    margin-bottom: 5px;}
	.preview-wrap .kv-file-content {display: inline-block;float: left;margin-bottom: -45px;}
	.preview-wrap .file-details-cell .explorer-caption {display: inline-block;vertical-align: middle;width: 98%;}
	.preview-wrap .explorer-frame .file-other-icon i {margin-left: 0;}
	.preview-wrap .file-details-cell::before{content: '';display: inline-block;height: 50px;width: 1px;vertical-align: middle;}
	.file-preview-success .file-desc button{font-size: 12px;margin-right: 10px;}
	.preview-wrap .file-actions-cell .file-desc { margin-bottom: 10px;}
	.file-preview-error .status-btn{float: left;margin-bottom: -30px;}
	.file-preview-error .file-actions-cell .file-desc {padding-left: 30px;padding-top: 3px;}
	
	.conver-pay-linkdin .left .title h2 {font-size: 24px;}*/
	/*.conver-pay-linkdin .right{display:none;}*/
	/*.conver-pay-linkdin  .left .border .title{ padding-left: 0;}
	.conver-pay-linkdin .left .border> div.row > div{margin-bottom: 25px;}
	.conver-pay-linkdin .upload-show-how{padding: 0 35px;}
	.conver-pay-linkdin .content-wrap-r{padding-left: 35px;}
	.conver-pay-linkdin .left .content-wrap-r-job {padding-top: 10px;padding-left: 35px;}*/
	
	.support-center .content-wrap {padding-right: 0;}
	/*----------------*/
}

@media (min-width: 768px){
	.navbar-toggle {
	    display: block;
	}
	
	.banner .item{
	    float: right;
	}
}


@media (min-width: 992px) {
  .navbar-toggle {
    display: none;
  }
  .navbar-collapse {
    width: auto;
    border-top: 0;
    padding-right: 0;
    padding-left: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar-collapse .nav-border-bottom>li>a>.caret{display: none;}
  .header .navbar-collapse .nav-border-bottom>li>a>.caret{display: inline-block;}
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
  
	.grade-pay .left,
	.grade-pay .right{
		float: right;
	}
}


@media only screen and (max-width:476px ) {
	.panda-show .swiper-slide {
	     padding: 0 13px; 
	}
}


@media only screen and (min-width: 768px) and (max-width: 992px) {
	.help .aca-rusumes li{width: 50%;}
	.help .aca-rusumes-tips .itme-block {margin: 35px 0;}
	
}


@media only screen and (max-width: 767px ) {
	.help .aca-rusumes li{width: 100%;}
	.help-search {padding: 40px 0;}
	.help-search .title {font-size: 30px;}
	.help-search .form-group .form-control {font-size: 12px;height: 40px;line-height: 40px;}
	.help-search .form-group .form-control-feedback {font-size: 18px;height: 40px;line-height: 40px;}
	.help-search .desc{font-size: 12px;}
	
	.search-result-foot {font-size: 16px;}
	.search-result-body .cont h6 {font-size: 16px;}
	
	.match-play-vip .select-radio{padding-right: 15px;}
	.match-play-vip .select-radio .pro{margin-bottom: 20px;}
	
	.resumelist-wrap .resumelist-detail { padding: 20px 20px 30px 20px;}
	
	.support-center .content-wrap {padding-left: 0;}
	
	.parseSuccessfullyModal h4,.parseSuccessfullyModal .sub-title{margin-bottom: 10px;}
	.parseSuccessfullyModal .cont .row>div{margin-bottom: 20px;}
	.parseSuccessfullyModal .cont .row>div:last-child{margin-bottom: 0;}
	.parseSuccessfullyModal .border .title {padding: 0;float: left;}
	.parseSuccessfullyModal .border .price {padding: 0;float: right;font-size: 20px;}
	.parseSuccessfullyModal .border .desc {min-height: auto;text-align: left;clear: both;font-size: 14px;}
	.parseSuccessfullyModal .border .button-group{text-align: right;}
	.parseSuccessfullyModal .border .button-group i{font-size: 14px;}
	.parseSuccessfullyModal .border .button-group .btn{font-size: 12px;padding: 3px 6px;}

	.resume-company .company li {width: 100%; margin: 5px 15px;float: none;}
}